




















import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";

@Component({
        directives: {
            mask
        },
})
export default class Dates extends Vue {

    menu: boolean = false;
    mask= "##.##.####";
    @Prop({})
    value!: Date;

    @Watch('value')
    onValueChanged(newVal: string, oldVal: string) {
        if(newVal){
            this.date = new Date(newVal);
            this.pickerValue = this.date.toISOString().substr(0,10);
            this.onPickerValueChanged();
        }
    }

    date = this.value ? new Date(this.value) : null;
    pickerValue:string|null = this.date ? this.date.toISOString().substr(0,10) : null;
    textFieldValue:string="";
    @Prop({})
    label!: string;

    lbl = this.label ? this.label : 'Tarih';


     @Prop() color!: string;

     private localColor = this.color ? this.color : ''

    @Watch('pickerValue')
    onPickerValueChanged(){
        if(this.pickerValue){
            this.textFieldValue = this.formatDate(this.pickerValue);
        }
    }

    mounted(){
        this.onPickerValueChanged();
    }

    formatDate (date:string):string {
        if (!date) return '';

        return date.split('-').reverse().join('.');

    };

    textFieldChanged(){

        if(this.textFieldValue.length == 10){
            this.pickerValue = this.textFieldValue.split('.').reverse().join('-');
            this.save();
        }
    }

    textFieldBlur(){
        if(this.textFieldValue.length == 10){
            this.pickerValue = this.textFieldValue.split('.').reverse().join('-');
            this.save();
        }else{

            this.pickerValue = null;
            this.textFieldValue="";
            this.$emit("input",null);
        }
    }

    cancel(){
        this.menu=false;
    }

    onkeyDown(e:KeyboardEvent){
        console.log(e);
        if(e.key == "Enter"){
            this.save();
            this.menu=false;
        }
    }

    save() {
        console.log("save event");
        if(this.pickerValue){
            this.$emit("input", new Date(this.pickerValue));
        }else{
            this.$emit("input", null);
        }
        this.menu=false;
    }
};
